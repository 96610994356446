'use client'
import React, { ChangeEventHandler, useEffect, useState } from 'react';
import { useGenerate } from './hooks/useGenerate';
import { useRouter } from 'next/navigation';

const TIMER_LENGTH_SECONDS = 15;
const UPDATE_INTERVAL = 500

const TOTAL_INTERVALS = TIMER_LENGTH_SECONDS * 1000 / UPDATE_INTERVAL;

const GenerateArtComponent = (props: { initialValue?: string }) => {
    const [inputValue, setInputValue] = useState(props.initialValue ?? '');
    const { isPending, mutate } = useGenerate();
    const router = useRouter()
    const [error, setError] = useState<null | string>(null);
    const [loadingProgress, setLoadingProgress] = useState(0);

    const handleInputChange = (event: any) => {
        setInputValue(event.target.value);
    };

    const handleGenerateClick = () => {
        if (inputValue.length < 10) {
            setError('Prompt must be at least 10 characters');
            return;
        }
        mutate({ prompt: inputValue }, {
            onSuccess: (body) => {
                router.push(`/view/${body.data.id}?prompt=${encodeURIComponent(inputValue)}`)
            }
        });
    };

    // when we start loading, start a timer
    useEffect(() => {
        let interval: NodeJS.Timeout;

        const countDownUntilZero = () => {
            setLoadingProgress(previousProgress => {
                if (previousProgress > 100) {
                    return 100
                }
                else return previousProgress + 100 / TOTAL_INTERVALS
            })
        }

        if (isPending) {
            setLoadingProgress(0);
            interval = setInterval(countDownUntilZero, UPDATE_INTERVAL);
        }
        return () => clearInterval(interval);
    }, [isPending]);


    return (
        <>
            <div className="flex-row">
                <h1 className="text-3xl py-8 w-full">What do you want to create?</h1>
                {error && <p className="text-red-500 pb-3">{error}</p>}
                <textarea
                    onChange={handleInputChange}
                    placeholder="Two samurai fighting with katanas on a bamboo bridge over a river in a mystical feudal Japan forest"
                    className="textarea textarea-bordered textarea-lg w-full h-56 md:h-40" >{props.initialValue}</textarea>
                <button className="btn btn-primary my-8" disabled={isPending} onClick={handleGenerateClick}>
                    {!isPending && 'Generate'}
                    {isPending && <span className="loading loading-spinner"></span>}
                </button>
                <div>
                    {isPending && <progress className="progress progress-primary w-full" value={Math.floor(loadingProgress)} max="100"></progress>}
                </div>
            </div>
        </>
    );
};

export default GenerateArtComponent;
