
import { GenerateApiResponse } from '@/app/api/generate/route';
import { useMutation, useQuery } from '@tanstack/react-query'

type GenerateOpts = {
    prompt: string,
}

const generate = async (opts: GenerateOpts): Promise<GenerateApiResponse> => {
    const response = await fetch(`/api/generate`, { 
        method: 'post', 
        body: JSON.stringify({ prompt: opts.prompt }) 
    });
    const parsed = await response.json();
    return parsed // return the url field from the response
}

const useGenerate = () => {
    return useMutation<GenerateApiResponse, Error, GenerateOpts>({
        mutationFn: generate,
    })
}

export { useGenerate }
